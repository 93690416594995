import Rails from '@rails/ujs';
import {AniX} from 'anix';
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";
import sourceFactory from "./autocomplete/source_factory";
import searchEngine from "./autocomplete/search_highlighter";
import i18n from "./i18n";

const initializeAutocomplete = function (inputEl, source, onSelected) {
        if (!inputEl) {
            return;
        }
        let autocompletion;
        const data = {
            src: async (query) => {
                try {
                    return await source.fetch(query);
                } catch (error) {
                    console.error(error);
                    return error;
                }
            },
            keys: source.keys
        }, styleOptionNode = function (node, data) {
            if (source != null && source.styleOptionNode) {
                source.styleOptionNode.call(this, node, data);
            }
            if (isAutocompleteOptionChecked(inputEl.parentElement?.parentElement?.parentElement, data)) {
                node.classList.add('checked');
            }
        }, config = {
            selector: () => inputEl, data: data, debounce: 300, searchEngine: searchEngine,
            resultsList: {
                element: (list, data) => {
                    const info = document.createElement("p");
                    info.classList.add('text-center', 'small', 'm-1');
                    if (data.results.length === 0) {
                        i18n.t('')
                        info.innerHTML = i18n.t('js.autocomplete.results_header.none', {
                            query: `<strong>${i18n.t('quoted_value', {value: data.query})}</strong>`
                        });
                    } else if (data.results.length !== data.matches.length) {
                        info.innerHTML = i18n.t('js.autocomplete.results_header.partial', {
                            number_shown: `<strong>${data.results.length}</strong>`,
                            total_size: `<strong>${data.matches.length}</strong>`
                        });
                    } else {
                        info.innerHTML = i18n.t('js.autocomplete.results_header.all', {
                            count: data.results.length,
                            total_size: `<strong>${data.matches.length}</strong>`
                        });
                    }
                    list.prepend(info);
                },
                noResults: true,
                maxResults: 50
            },
            resultItem: {
                element: styleOptionNode,
                highlight: true
            },
            events: {
                input: {
                    focus() {
                        const inputValue = autocompletion.input.value;
                        if (inputValue.length) autocompletion.start();
                    },
                    blur() {
                        autocompletion.close();
                    },
                    open() {
                        const canFitBelow =
                            autocompletion.input.getBoundingClientRect().bottom +
                            autocompletion.list.getBoundingClientRect().height <=
                            (window.innerHeight || document.documentElement.clientHeight);
                        autocompletion.list.classList.toggle('position-above', !canFitBelow);
                    },
                },
            }
        };

        inputEl.addEventListener("selection", (e) => {
            if (source && source.onOptionSelected) {
                source.onOptionSelected(e.detail.selection, this.valueInputTarget);
            }
            onSelected.call(e.target, e.detail.selection);
            // Reset search field
            e.target.value = null;
        });

        // Importing {autoComplete as AutoComplete} results in an error
        // noinspection JSPotentiallyInvalidConstructorUsage
        autocompletion = new autoComplete(config);
        return autocompletion;
    },
    isAutocompleteOptionChecked = function (wrapper, selection) {
        if (wrapper && selection.value) {
            return wrapper.querySelector(`[value="${selection.value.id}"]`) != null;
        }
    }


const PigFilter = {};

PigFilter.autosubmitIfNecessary = function ($form) {
    if ($form.attr('data-autosubmit')) {
        Rails.fire($form.get(0), 'submit');
    } else if ($form.attr('data-controller') === 'auto-submit') {
        $form.get(0).requestSubmit();
    }
};
PigFilter.initialize = function (configuration, objectName, wrapper) {
    if ($.isArray(objectName)) {
        $.each(objectName, function (_, name) {
            PigFilter._initializeInputs(configuration, name);
        });
    } else {
        PigFilter._initializeInputs(configuration, objectName);
    }

    let $wrapper;
    if (wrapper == null) {
        $wrapper = $('body');
    } else if (typeof wrapper === "string" || wrapper instanceof Element || wrapper instanceof HTMLDocument) {
        $wrapper = $(wrapper);
    } else {
        $wrapper = wrapper;
    }
    $wrapper.find('a[href="#add-filter"]').each(function () {
        PigFilter._initializeAddFilter($(this).prev('.card-column'), $($(this).attr('data-target')));
    });
    $wrapper.find('a[href="#remove-filter"]').off('click').on('click', function (e) {
        let $card = $(this).closest('.card'),
            $parent = $card.closest('.card-column');
        $card.addClass('hidden');
        let $listGroup = $card.find('.list-group');
        if ($listGroup.length) {
            if ($listGroup.children().length) {
                $listGroup.html('');
                PigFilter.autosubmitIfNecessary($card.closest('form'));
            }
        } else {
            $card.find(':input').val('').trigger('change').prop('disabled', true).addClass('disabled');
        }
        $card.find('> .card-header .current_value').text('');
        $card.find('.btn-daterangepicker .current_value').text(configuration.localization.dateFilter.all);
        if ($parent.find('.card:visible').length === 0) {
            $parent.find('.no_filter_text').removeClass('d-none');
        }
        $parent.parent().find('a[href="#add-filter"]').removeClass('d-none');
        e.preventDefault();
        return false;
    });
};

PigFilter._initializeInputs = function (configuration, objectName) {
    let inputSelectorPrefix;
    if (objectName.includes(' ')) {
        inputSelectorPrefix = objectName.split(' ').map(function (part) {
            if (part.startsWith('.') || part.startsWith('#')) {
                return part;
            } else {
                return '.' + part.replace(/(\]\[)?\]$/, '').replace(/(\]?\[)| |\-/g, '_');
            }
        }).join(' ');
    } else {
        inputSelectorPrefix = '.' + objectName.replace(/(\]\[)?\]$/, '').replace(/(\]?\[)| |\-/g, '_');
    }


    PigFilter._initializeTextFilter(inputSelectorPrefix, 'ear_tag_or_code', configuration.localization.textFilter);
    PigFilter._initializeTextFilter(inputSelectorPrefix, 'father_ai_barcode', configuration.localization.textFilter);

    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'age', $.extend({}, configuration.localization.numericFilter, configuration.localization.ageFilter));
    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'litter_number', configuration.localization.numericFilter);
    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'current_parity', configuration.localization.numericFilter);

    let weightFilterLocalization = $.extend({}, configuration.localization.numericFilter, configuration.localization.weightFilter);
    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'birth_weight', weightFilterLocalization);
    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'weaning_weight', weightFilterLocalization);
    PigFilter._initializeRangeFilter(inputSelectorPrefix, 'slaughter_weight', weightFilterLocalization);

    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'pig_types');
    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'sow_states');
    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'sex');
    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'neutered');
    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'weaned');
    PigFilter._initializeRadioOrCheckBoxFilter(inputSelectorPrefix, 'slaughter_ratings', function () {
        return this.closest('.form-check').find('label span').attr('title');
    });

    PigFilter._initializeDaterangepickerFilter(inputSelectorPrefix, 'born_on', configuration.localization.dateFilter);
    PigFilter._initializeDaterangepickerFilter(inputSelectorPrefix, 'weaned_at', configuration.localization.dateFilter);
    PigFilter._initializeDaterangepickerFilter(inputSelectorPrefix, 'died_on', configuration.localization.dateFilter);
    PigFilter._initializeDaterangepickerFilter(inputSelectorPrefix, 'slaughtered_on', configuration.localization.dateFilter);

    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'location',
        sourceFactory.create('polymorphic_locations', {
            includeCustomers: !configuration['customer_id'] && configuration['can_manage_customers'],
            extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)
        }),
        function (suggestion) {
            let $input = this.find('input');
            return $input.attr('name', $input.attr('name').replace('{model_name}', suggestion._type));
        }
    );

    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'group', sourceFactory.create('generic', {
            endpoint: '/pig_groups/typeahead',
            extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)
        })
    );

    const pigValueTransformer = function (pig) {
        let icon = 'dot-circle';
        if (pig.current_code && pig.current_code.length) {
            icon = 'tag'
        } else if (pig.breed_registry_code && pig.breed_registry_code.length) {
            icon = 'book'
        }
        this.find('span').html('<i class="fas fa-' + icon + '"></i> ' + pig.short_identifier);
    };
    let fatherSource;
    if (configuration.aiBoars) {
        if (configuration.aiBoars.hasOwnBoars == null || configuration.aiBoars.hasOwnBoars) {
            fatherSource = sourceFactory.create('ai_boars', PigFilter._addConfigurationToAutocompleteParams({includeOwnBoars: true}, configuration));
        } else {
            fatherSource = sourceFactory.create('ai_boars', PigFilter._addConfigurationToAutocompleteParams({}, configuration));
        }
    } else {
        fatherSource = sourceFactory.create('pigs', {extraParams: PigFilter._addConfigurationToAutocompleteParams({'filter[pig_types]': 'breeding_male'}, configuration)});
    }
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'father', fatherSource, pigValueTransformer
    );

    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'mother',
        sourceFactory.create('pigs', {extraParams: PigFilter._addConfigurationToAutocompleteParams({'filter[pig_types]': 'breeding_female'}, configuration)}),
        pigValueTransformer
    );

    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'breed', sourceFactory.create('breeds', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)}),
        function (breed) {
            if (breed.label) this.find('span').text((breed.label));
        }
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'mother_breed',
        sourceFactory.create('breeds', {extraParams: PigFilter._addConfigurationToAutocompleteParams({sex: 'female'}, configuration)}),
        function (breed) {
            if (breed.label) this.find('span').text((breed.label));
        }
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'father_breed',
        sourceFactory.create('breeds', {extraParams: PigFilter._addConfigurationToAutocompleteParams({sex: 'male'}, configuration)}),
        function (breed) {
            if (breed.label) this.find('span').text((breed.label));
        }
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'disease', sourceFactory.create('diseases', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)})
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'drug', sourceFactory.create('drugs', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)})
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'anomaly', sourceFactory.create('anomalies', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)})
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'death_cause', sourceFactory.create('death_causes', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)})
    );
    PigFilter._initializeAutocompleteFilter(
        inputSelectorPrefix, 'cull_reason', sourceFactory.create('cull_reasons', {extraParams: PigFilter._addConfigurationToAutocompleteParams({}, configuration)})
    );
};

PigFilter._addConfigurationToAutocompleteParams = function (params, configuration) {
    if (configuration['customer_id']) {
        params['customer_id'] = configuration['customer_id'];
    }
    return params
}

PigFilter._updateListGroupCardValue = function ($card) {
    let values = [];
    $card.find('.card-body .list-group .list-group-item').each(function () {
        values.push($(this).text());
    });
    $card.find('> .card-header .current_value').text(values.sort().join(', '));
};

PigFilter._listGroupRemoveListener = function (e) {
    let $this = $(this), $form = $this.closest('form'), $card = $this.closest('.card');
    $this.closest('.list-group-item').remove();
    PigFilter._updateListGroupCardValue($card);
    PigFilter.autosubmitIfNecessary($form);

    e.preventDefault();
    return false;
};

PigFilter._initializeAutocompleteFilter = function (inputSelectorPrefix, searchName, source, selectedValueTransform) {
    let $searchInput = $(inputSelectorPrefix + '_' + searchName + '_search input.search'),
        autocomplete = initializeAutocomplete($searchInput.get(0), source,
            function (selection) {
                let $selectedValue = $('<div></div>', {
                        class: 'list-group-item'
                    }),
                    $removeButton = $('<a></a>', {
                        href: 'javascript:',
                        class: 'btn btn-sm btn-flat-danger-on-hover mt-n1 mb-n1 mr-n2 float-right remove_button'
                    }).append('<i class="fas fa-times">'),
                    $idInput = $('<input />', {
                        type: 'hidden',
                        name: $searchInput.attr('data-id-input-name'),
                        value: selection.value.id
                    });
                $selectedValue.append($removeButton).append($('<span/>').text(selection.value.name)).append($idInput);
                if (selectedValueTransform) {
                    selectedValueTransform.call($selectedValue, selection.value)
                }

                let $existingInput = $('input[name="' + $idInput.attr('name') + '"][value="' + $idInput.val() + '"]');
                if ($existingInput.length > 0) {
                    AniX.fromTo($existingInput.closest('.list-group-item').get(0), 0.33, {
                        backgroundColor: '#ffc553'
                    }, {
                        backgroundColor: null
                    });
                    return;
                }

                $removeButton.on('click', PigFilter._listGroupRemoveListener);
                let $card = $(this).closest('.card.typeahead_filter');
                $card.find('.list-group').append($selectedValue);
                PigFilter._updateListGroupCardValue($card);
                PigFilter.autosubmitIfNecessary($(this).closest('form'));
            });
    if (autocomplete) {
        $(document).one('turbo:before-cache', function () {
            autocomplete.close();
            autocomplete.unInit();
        });
    }

    $searchInput.closest('.card.typeahead_filter').find('.list-group a.remove_button').on('click', PigFilter._listGroupRemoveListener);
    PigFilter._updateListGroupCardValue($searchInput.closest('.card.typeahead_filter'));
};

PigFilter._initializeTextFilter = function (inputSelectorPrefix, attr, localization) {
    let $input = $(inputSelectorPrefix + '_' + attr + ' input'),
        updatecardTitle = function () {
            let value = $(this).val();
            $(this).closest('.card').find('> .card-header .current_value').text(value.length ? localization.contains.replace(/%{value}/, value) : '');
        };
    $input.on('input', updatecardTitle).each(updatecardTitle);
    if ($input.closest('.card').hasClass('hidden')) {
        $input.prop('disabled', true);
    }
};

PigFilter._initializeRangeFilter = function (inputSelectorPrefix, attr, localization) {
    let $inputs = $(inputSelectorPrefix + '_min_' + attr + ' input, ' + inputSelectorPrefix + '_max_' + attr + ' input'),
        updatecardTitle = function () {
            let min = $(inputSelectorPrefix + '_min_' + attr + ' input').val(),
                max = $(inputSelectorPrefix + '_max_' + attr + ' input').val(),
                label;
            if (min && min.length && max && max.length) {
                if (min === max) {
                    label = min;
                    if (localization.addOn) {
                        label += ' ' + localization.addOn;
                    }
                } else {
                    if (localization.addOn) {
                        max += ' ' + localization.addOn;
                    }
                    label = localization.between.replace(/%{minimum}/, min).replace(/%{maximum}/, max);
                }
            } else if (min && min.length) {
                if (localization.addOn) {
                    min += ' ' + localization.addOn;
                }
                label = localization.minimum.replace(/%{minimum}/, min);
            } else if (max && max.length) {
                if (localization.addOn) {
                    max += ' ' + localization.addOn;
                }
                label = localization.maximum.replace(/%{maximum}/, max);
            } else {
                label = '';
            }
            $(this).closest('.card').find('> .card-header .current_value').text(label);
        };
    $inputs.on('input', updatecardTitle);
    updatecardTitle.call($inputs.get(0));
    if ($inputs.closest('.card').hasClass('hidden')) {
        $inputs.prop('disabled', true);
    }
};

PigFilter._initializeRadioOrCheckBoxFilter = function (inputSelectorPrefix, attr, inputToLabel) {
    let $inputs = $(inputSelectorPrefix + '_' + attr + ' input[type=radio], ' + inputSelectorPrefix + '_' + attr + ' input[type=checkbox]'),
        updatecardTitle = function () {
            let $checkedItems = $inputs.filter(':checked'),
                checkedItemLabels = [];
            if ($checkedItems.length && $checkedItems.length < $inputs.length) {
                $checkedItems.each(function () {
                    if ($(this).attr('value') !== '') {
                        checkedItemLabels.push(inputToLabel ? inputToLabel.call($(this)) : $(this).closest('.form-check').find('label').text());
                    }
                });
            }
            $(this).closest('.card').find('> .card-header .current_value').text(checkedItemLabels.join(', '));
        };
    $inputs.on('change', updatecardTitle);
    updatecardTitle.call($inputs.get(0));
    if ($inputs.closest('.card').hasClass('hidden')) {
        $inputs.prop('disabled', true);
    }
};

PigFilter._initializeDaterangepickerFilter = function (inputSelectorPrefix, attr, localization) {
    let daterangepickerOptions = {
            minDate: moment('2014-01-01', moment.ISO_8601),
            maxDate: moment().endOf('day'),
            showDropdowns: true,
            showWeekNumbers: true,
            timePicker: false,
            linkedCalendars: false,
            alwaysShowCalendars: true,
            showCustomRangeLabel: false,
            opens: 'right',
            drops: 'up',
            locale: {
                applyLabel: localization.applyLabel,
                cancelLabel: localization.cancelLabel,
                fromLabel: localization.fromLabel,
                toLabel: localization.toLabel,
                customRangeLabel: localization.customRangeLabel
            }
        },
        begin = $(inputSelectorPrefix + '_' + attr + '_begin input').val(),
        end = $(inputSelectorPrefix + '_' + attr + '_end input').val();
    begin = begin && begin.length ? moment(begin, moment.ISO_8601) : null;
    end = end && end.length ? moment(end, moment.ISO_8601) : null;
    if (begin) {
        daterangepickerOptions['startDate'] = begin;
    }
    if (end) {
        daterangepickerOptions['endDate'] = end;
    }
    let $picker = $(inputSelectorPrefix + '_' + attr + '_picker .btn-daterangepicker'),
        $card = $picker.closest('.card');
    $picker.daterangepicker(daterangepickerOptions, function (start, end, label) {
        if (label == null || label === this.locale.customRangeLabel) {
            label = DateRangeHelper.formatRelative(start, end, localization.dateRange);
        }
        this.element.find('.current_value').text(label);
        let $card = this.element.closest('.card');
        $card.find('> .card-heading > .card-title > .current_value').text(label);
        $(inputSelectorPrefix + '_' + attr + '_begin input').val(start.format('YYYY-MM-DD'));
        $(inputSelectorPrefix + '_' + attr + '_end input').val(end.format('YYYY-MM-DD'))
            .get(0).dispatchEvent(new Event('change', {'bubbles': true}));
    });
    if (begin && end) {
        let label = DateRangeHelper.formatRelative(begin, end, localization.dateRange);
        $picker.find('.current_value').text(label);
        $card.find('> .card-header .current_value').text(label);
    }
    if ($card.hasClass('hidden')) {
        $card.find(':input').prop('disabled', true);
    }
};

PigFilter._initializeAddFilter = function ($wrapper, $modal) {
    if ($modal.length === 0) {
        console.error('Add pig filter modal not found');
        return;
    }

    let fuseItems = [],
        fuseOptions = {
            id: 'type',
            shouldSort: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [{
                name: 'title',
                weight: 0.5
            }, {
                name: 'description',
                weight: 0.15
            }, {
                name: 'keywords',
                weight: 0.35
            }]
        },
        fuse = new Fuse(fuseItems, fuseOptions);

    $modal.find('.filter_item').each(function () {
        let keywords = [];
        $(this).attr('data-keywords').split(',').forEach(function (item) {
            if (item.length) {
                keywords.push(item);
            }
        });
        fuseItems.push({
            type: $(this).attr('data-filter-type'),
            title: $(this).find('.filter_title').text(),
            description: $(this).find('.filter_description').text(),
            keywords: keywords
        });
    });

    $modal.find('.filter_item').on('click', function (e) {
        let type = $(this).attr('data-filter-type'),
            $wrapperItem = $wrapper.find('.pig_filter[data-type="' + type + '"]');

        if ($wrapperItem.length) {
            $modal.modal('hide');

            $wrapperItem.removeClass('hidden').find(':input').prop('disabled', false).removeClass('disabled');
            $wrapper.find('.no_filter_text').addClass('d-none');
            $wrapperItem.find('.card-collapse').off('shown.bs.collapse.trigger_input_focus').one('shown.bs.collapse.trigger_input_focus', function () {
                $(this).find(':input:visible').focus();
            }).collapse('show');

            if ($wrapper.find('.pig_filter:not(:visible)').length) {
                $wrapper.parent().find('a[href="#add-filter"]').removeClass('d-none');
            } else {
                $wrapper.parent().find('a[href="#add-filter"]').addClass('d-none');
            }
        } else {
            console.error('Whoops!  ‘' + type + '’ filter not found');
        }

        e.preventDefault();
        return false;
    });

    $modal.find('.filter_search').on('input search', $.debounce(50, function () {
        let filter = $(this).val();
        if (filter.length === 0) {
            $modal.find('.filter_item').removeClass('d-none');
            $modal.find('.nothing_found_text').addClass('d-none');
            return;
        }

        let result = fuse.search(filter);
        $modal.find('.filter_item').each(function () {
            this.classList.toggle('d-none', !result.includes(this.dataset.filterType));
        });
        $modal.find('.nothing_found_text').toggleClass('d-none', result.length === 0);

    })).on('keydown', function (e) {
        if (e.keyCode === 13) {
            // Return
            $($modal.find('.filter_item:visible:not(.disabled)').get(0)).click();
            e.preventDefault();
            return false;
        }
    });

    $modal.find('.filter_search, .filter_item').on('keydown', function (e) {
        if (e.keyCode === 38) {
            // Up
            let $prev = $(this).prev('.filter_item');
            while ($prev.length && ($prev.hasClass('disabled') || !$prev.is(':visible'))) {
                $prev = $prev.prev('.filter_item');
            }
            if ($prev.length) {
                $prev.focus();
            } else {
                $modal.find('.filter_search').focus();
            }
            e.preventDefault();
            return false;
        } else if (e.keyCode === 40 || e.keyCode === 9) {
            // Down or Tab
            if ($(this).hasClass('filter_search')) {
                $($modal.find('.filter_item:visible:not(.disabled)').get(0)).focus();
            } else {
                let $next = $(this).next('.filter_item');
                while ($next.length && ($next.hasClass('disabled') || !$next.is(':visible'))) {
                    $next = $next.next('.filter_item');
                }
                $next.focus();
            }
            e.preventDefault();
            return false;
        }
    });
    $modal.find('.filter_item').on('keydown', function (e) {
        if (e.keyCode === 8) {
            // Backspace
            $modal.find('.filter_search').focus();
        }
    });

    $modal.on('show.bs.modal', function () {
        $modal.find('.filter_search').val('').trigger('search');
        $modal.find('.filter_item').each(function () {
            let $modalItem = $(this),
                type = $modalItem.attr('data-filter-type'),
                $filterItem = $wrapper.find('.pig_filter[data-type="' + type + '"]');
            if ($filterItem.length === 0) {
                console.error('Whoops!  ‘' + type + '’ filter not found');
            } else {
                let added = $filterItem.is(':visible');
                $modalItem.toggleClass('disabled', added);
                $modalItem.find('.icon_unadded').toggle(!added);
                $modalItem.find('.icon_added').toggle(added);
            }
        });
    }).on('shown.bs.modal', function () {
        $modal.find('.filter_search').focus();
    });
};

module.exports = PigFilter;